import { ArticleContent } from '../templates/post.style'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { color, fontSize, fontSizeMobile } from '../assets/styles/variables'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import Seo from '../components/Seo/Seo'
import StickyBox from '../components/StickyBox/StickyBox'
import { Link, graphql } from 'gatsby'

const H1 = styled.h1`
  font-size: 40px;
  color: ${color.primary};
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`

const H2 = styled.h2`
  color: ${color.textDark};
  font-size: ${fontSize.h3};
  line-height: 1.2;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    font-size: ${fontSizeMobile.h3};
  }
`

const H3 = styled.h3`
  margin-left: 25px;
  color: ${color.primary};
  font-size: ${fontSize.h4};
  transition: color 0.2s ease;
  font-weight: 400;
  &:hover{
      color: ${color.linkHover}
  }
  @media screen and (max-width: 768px) {
    font-size: ${fontSizeMobile.h4};
  }
`
const CustomLink = styled(Link)`
  text-decoration: none;
`

export default function SiteMap({ data }) {
  const pages = data.allWpPage.edges
  const posts = data.allWpPost.edges
  const authors = data.allWpUser.edges
  return (
    <Layout>
      <Seo title="Mapa strony - Rankingdobrychpolis.pl" description="" keywords="mapa strony" pathname="/mapa-strony" />
      <Container>
        <Row>
          <Col className="mt-4">
            <H1>Mapa strony</H1>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <ArticleContent style={{ marginTop: 0 }}>
              <H2>Strony</H2>
              {pages.map((page) => {
                return (
                  <CustomLink to={page.node.uri}>
                    <H3>- {page.node.title}</H3>
                  </CustomLink>
                )
              })}
            <CustomLink to="/blog/"><H2>Blog</H2></CustomLink>
              {posts.map((post) => {
                return (
                  <CustomLink to={post.node.uri}>
                    <H3>- {post.node.title}</H3>
                  </CustomLink>
                )
              })}
              <H2>Autorzy</H2>
              {authors.map((author) => {
                return (
                  <CustomLink to={author.node.uri}>
                    <H3>- {author.node.name}</H3>
                  </CustomLink>
                )
              })}
            </ArticleContent>
          </Col>
          <Col lg={4}>
            <StickyBox />
          </Col>
        </Row>
        <Row>
          <LastPosts />
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SiteMap {
    allWpPage(filter: { id: { nin: ["cG9zdDoyNA==", "cG9zdDoxNw=="] } }, sort: { fields: seo___title }) {
      edges {
        node {
          title
          uri
          id
        }
      }
    }
    allWpPost(filter: { categories: { nodes: { elemMatch: { slug: { ne: "towarzystwo-ubezpieczeniowe" } } } } }) {
      edges {
        node {
          uri
          title
        }
      }
    }
    allWpUser(filter: {id: {nin: ["dXNlcjox", "dXNlcjo0"]}}) {
    edges {
      node {
        uri
        name
      }
    }
  }
  }
`
